@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: "Poppins" !important;
}
h4,
h6 {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-left: 20%;
}

.MuiSvgIcon-colorPrimary {
  color: green !important;
}
.wallet-bal {
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: gray;
}
.wallet-block {
  cursor: pointer;
  min-width: 122px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  flex: 1;
}
.wallet-wrapper {
  display: flex;
  width: 80%;
}

.bottom-app-bar{
  display: none!important;
}

.wrong-net {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-connected-image{
  height: 20px;
    width: 20px;
}


.wallet-num {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-left: 20%;
  margin-left: 18%;
}
.input {
  font-size: 16px;
}
body {
  background: #404040;
}
input[type="file"] {
  display: none;
}
.container-div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upper-strip {
  height: 200px;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #e80368;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #e80368 !important;
}
.main {
  display: flex;
  z-index: 1;
  height: 95vh;
  width: 80vw;
  text-align: center;
  border-radius: 5px;
  background: white;
  animation-name: animate-container;
  animation-duration: 0.5s;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02),
    0px 1px 12px rgba(0, 0, 0, 0.12);
}

p {
  word-break: break-all;
}
.left-panel {
  flex-basis: 40%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #282c3459;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/imgs/bg.jpeg");
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.right-panel {
  flex-basis: 60%;
  width: 70%;
  height: 100%;
  background-color: #eef2f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px !important;
  overflow-y: auto;
}
.social {
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  color: #fff;
}
.pink-bubble {
  height: 300px;
  width: 300px;
  background-color: rgb(220, 30, 93);
  border-radius: 50%;
  position: absolute;
  top: 20;
  left: 30px;
  z-index: 1;
}

.yellow-bubble {
  height: 200px;
  width: 300px;
  background-color: rgb(255, 247, 0);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -10px;
  z-index: 1;
}
.purple-bubble {
  height: 500px;
  width: 200px;
  background-color: rgb(0, 51, 255);
  border-radius: 50%;
  position: absolute;
  top: 100;
  right: -20px;
  z-index: 1;
}

.red-bubble {
  height: 200px;
  width: 200px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  position: absolute;
  top: 500;
  bottom: 2px;
  z-index: 1;
}

.glass {
  height: 100%;
  width: 100%;
  background-color: #0000009c;
  position: absolute;
  z-index: 2;
  backdrop-filter: blur(100px);
}
.photo1 {
  display: inline-block;
  height: 300px;
  width: 300px;
  vertical-align: middle !important;
  overflow: hidden;
  margin-top: 50px;
}
.btn {
  background: #0e4da4;
}

.MuiAppBar-colorPrimary {
  background: #404040 !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: #4040404d !important;
}

.wallet-info {
  margin: 10px;
  justify-self: flex-start;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02),
    0px 1px 12px rgba(0, 0, 0, 0.12);
}

.connect-wallet {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
}

.wallet-info-title {
  text-align: left;
  padding: 10px;
}
.brand-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.brand-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  border-bottom: 1px solid #f2f2f2;
}
hr {
  margin: 0;
  background-color: #f2f2f2 !important;
  color: #f2f2f2;
}
.MuiButton-containedPrimary {
  background-color: #e80368 !important;
}
.MuiButton-label {
  font-family: "Poppins" !important;
}
.MuiCircularProgress-colorPrimary {
  color: rgb(232, 3, 104) !important;
}

@keyframes animate-container {
  from {
    opacity: 0.5;
    /*transform: scale(1.1);*/
  }
  to {
    opacity: 1;
    /* transform: scale(1);*/
  }
}
.add-alley-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem;
  text-align: left;
  padding: 15px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.div-text {
  flex-basis: 100%;
}
.div-btn {
  float: right;
  flex-basis: 100%;
}

.calc-div {
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  padding: 15px;
  margin-top: 15px;
  margin-right: 15px;
  background-color: #fff;
  margin-left: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.right-p {
  font-size: 10;
  color: #f50057;
  float: right !important;
}
.reward-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}
.div-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}
.left-div-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding-left: 10px;
  border-left: 1px solid silver;
  margin-left: 10px;
}
.approve-claim {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.approve-div {
  flex-basis: 48%;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02),
    0px 1px 12px rgba(0, 0, 0, 0.12);
}
.logo-div {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 10px;
}
.logo-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
.text-div {
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(240, 240, 240);
}

@media screen and (max-device-width: 1024px) {
  /* STYLES HERE */
  .left-panel {
    display: none;
  }
  .right-panel {
    flex-basis: 100%;
  }
  .wrong-net {
    margin: 20px 10px;
  }
  .add-chain-button {
    display: none !important;
  }
  .add-token-button {
    display: none !important;
  }
  .bottom-app-bar {
    display: block!important;
    position: fixed!important;
    bottom: 0;
    width: 80%!important;
  }
  .connect-wallet{
    margin-bottom: 60px;
  }
  .disconnet-button-desk{
    display: none!important;
  }
}

@media (max-width: 1100px) {
  .main {
    width: 80% !important;
  }
  .text-wraper {
    flex-basis: 100% !important;
  }
  .btn-wrapper {
    flex-basis: 100% !important;
  }
  .add-alley-div {
    flex-direction: column !important;
  }
}

@media (max-width: 1000px) {
  .createRow {
    margin: 0px 0px !important;
  }
  .text-wraper {
    flex-basis: 100% !important;
  }
  .btn-wrapper {
    flex-basis: 100% !important;
  }
  .add-alley-div {
    flex-direction: column !important;
  }
  .calc-div {
    flex-direction: column !important;
  }
  .div-wrapper {
    flex-basis: 100% !important;
  }
  .left-div-wrapper {
    flex-basis: 100% !important;
    margin-left: none !important;
    border-left: none !important;
    padding-left: 0px !important;
  }
}


@media (max-width: 600px) {
  .main {
    display: block;
    width: 100% !important;
    height: calc(100vh - calc(100vh - 100%)) !important;
  }
  .container-div {
    height: 100%;
    display: block;
  }
  .upper-strip {
    display: none;
  }
  .right-panel {
    width: 100%;
    height: calc(100vh - calc(100vh - 100%)) !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .form-wrapper {
    padding: 20px;
  }
  .wallet-info {
    height: 100%;
  }
  .connect-wallet {
    height: inherit;
  }
  .approve-claim {
    flex-direction: column;
  }
  .approve-div {
    margin-top: 10px !important;
    flex-basis: 100% !important;
  }
  .bottom-app-bar {
   
    width: 100%!important;
  }
}

@media (max-width: 425px) {
  .wallet-wrapper {
    flex-direction: column-reverse;
  }
  .wallet-block {
    margin-bottom: 60px;
  }
}

